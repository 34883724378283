import type { GetStaticProps, NextPage } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import SparseLayout from '../layouts/Sparse'
import PageWithLayoutType from '../types/pageWithLayout'

const Error404Page: NextPage = () => {
  const { t } = useTranslation('lobby')
  return (
    <div>
      <h1>{t('uhoh')}</h1>
      <p>{t('error404')}</p>
    </div>
  )
}

;(Error404Page as PageWithLayoutType).layout = SparseLayout

export const getStaticProps: GetStaticProps = async ({ locale = 'en-US' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['lobby'])),
    },
  }
}

export default Error404Page
